const data = {
  routes: ["about", "early-access"],
  SEO: {
    title: `Skyweaver - A Trading Card Game from Another Dimension`,
    url: `https://www.skyweaver.net`,
    desc: `A Worldwide Competitive Trading Card Game. Free to Play from Phone, Tablet & PC. Battle, Win, Own, Trade. Easy to Learn, Strategic, and Incredibly Fun. Play Now.`,
    img: `https://skyweaver.net/images/skyweavercover.jpg`
  },
  sectionLanding: {
    title: `A Trading Card Game from Another Dimension`,
    earlyAccessCTA: `Join The Waitlist`,
    quote: {
      text: `"I cannot wait for people to play Skyweaver. It's a brand new gaming experience that resurrects all the fun of childhood collectible card games - in a browser - and with the ability to trade cards just like in the old days."`,
      credit: `- Alexis Ohanian, Reddit Co-Founder`
    },
    quotes: [
      {
        text: "In 2014, Hearthstone’s release set a new standard for digital trading card games. The bar may have been raised once again. Skyweaver is a genre-defining digital TCG experience.",
        label: "NFT Plazas",
        image: { url: "/images/quotes/nft-plazas.png" }
      },
      {
        text: "Skyweaver is everything you'd hope for in a TCG and MUCH MORE. It's a brand new gaming experience that resurrects all the fun of childhood collectible card games - in a browser - and with the ability to trade cards just like in the old days.",
        label: "Alexis Ohanian, Reddit Co-Founder",
        image: { url: "/images/quotes/alexis.jpg", type: "profile" }
      },
      {
        text: "This is the best blockchain game I have played in the 4 years I've been testing them — and by some margin.",
        label: "Token Gamer",
        image: { url: "/images/quotes/token-gamer.png" }
      },
    ]
  },
  sectionIntro: {
    title: `Welcome To Sky`,
    text: `Skyweaver: the browser-based cross platform card game where you can own, trade and gift your cards. Deeply strategic gameplay combined with a player-owned marketplace lets you win tradable NFT cards on your journey to becoming a legendary Skyweaver!`
  },
  sectionGameplay: {
    title: `Battle, Summon, Compete`,
    subtitle: `Summon creatures, conjure spells, and battle other Skyweavers to compete for scarce resources.`,
    gameplayFields: [
      {
        image: `/images/2-gameplay/0.png`,
        text: `Summon creatures, cast spells, and battle other Skyweavers to win new cards.`
      },
      {
        image: `/images/2-gameplay/1.png`,
        text: `Easy for anyone to learn. Deep and complex for card game masters. `
      },
      {
        image: `/images/2-gameplay/2.png`,
        text: `Play casually or compete to become the most legendary Skyweaver.`
      }
    ]
  },
  sectionCards: {
    cards: [
      {
        title: `Strength`,
        arrayValue: [0, -1],
        subtitle: `Call upon powerful units and cast devastating spells to overpower and crush your enemies and bolster your allies. All will tremble before your unstoppable might.`,
        image0: `/images/3-cards/STR/0.png`,
        image1: `/images/3-cards/STR/1.png`,
        image2: `/images/3-cards/STR/2.png`,
        image3: `/images/3-cards/STR/3.png`,
        heroImg: `/images/heroes/hero-giaco-01@6x.png`,
        prismImg: `/images/prisms/STR.png`
      },
      {
        title: `Wisdom`,
        arrayValue: [1, -1],
        subtitle: `Your deep understanding of the sky offers you advantages beyond measure. Wield ancient power and your own spirit to humble your foes.`,
        image0: `/images/3-cards/WIS/0.png`,
        image1: `/images/3-cards/WIS/1.png`,
        image2: `/images/3-cards/WIS/2.png`,
        image3: `/images/3-cards/WIS/3.png`,
        heroImg: `/images/heroes/hero-brian-01@6x.png`,
        prismImg: `/images/prisms/WIS.png`
      },
      {
        title: `Heart`,
        arrayValue: [2, -1],
        subtitle: `Harness the powers of both life and death to vanquish your foes and keep your allies coming back from beyond the grave. Who can oppose death itself?`,
        image0: `/images/3-cards/HRT/0.png`,
        image1: `/images/3-cards/HRT/1.png`,
        image2: `/images/3-cards/HRT/2.png`,
        image3: `/images/3-cards/HRT/3.png`,
        heroImg: `/images/heroes/hero-brian-02@6x.png`,
        prismImg: `/images/prisms/HRT.png`
      },
      {
        title: `Agility`,
        arrayValue: [3, -1],
        subtitle: `Move and strike with the fury of lightning, outpacing and overwhelming all challengers in a flurry of speed. Your foes won’t even know what hit them.`,
        image0: `/images/3-cards/AGL/0.png`,
        image1: `/images/3-cards/AGL/1.png`,
        image2: `/images/3-cards/AGL/2.png`,
        image3: `/images/3-cards/AGL/3.png`,
        heroImg: `/images/heroes/hero-giaco-02@6x.png`,
        prismImg: `/images/prisms/AGY.png`
      },
      {
        title: `Intellect`,
        arrayValue: [4, -1],
        subtitle: `Orchestrate elaborate strategies to out-think and dominate all who oppose you, and leave them helpless to retaliate. Your mind is the greatest weapon of all.`,
        image0: `/images/3-cards/INT/0.png`,
        image1: `/images/3-cards/INT/1.png`,
        image2: `/images/3-cards/INT/2.png`,
        image3: `/images/3-cards/INT/3.png`,
        heroImg: `/images/heroes/hero-xavi-01@6x.png`,
        prismImg: `/images/prisms/INT.png`
      },
      {
        title: `Strength + Wisdom`,
        arrayValue: [1, 0],
        subtitle: `Strength of body and of soul walk hand in hand, granting you unshakable resolve. True victory belongs not to those who destroy, but those who endure.`,
        image0: `/images/3-cards/STR/0.png`,
        image1: `/images/3-cards/WIS/1.png`,
        image2: `/images/3-cards/STR/2.png`,
        image3: `/images/3-cards/WIS/2.png`,
        heroImg: `/images/heroes/hero-giaco-12@6x.png`,
        prismImg: `/images/prisms/STW.png`
      },
      {
        title: `Strength + Heart`,
        arrayValue: [2, 0],
        subtitle: `Wield your mastery over life and death to bring your mightiest units back again and again. Your allies will shrug off death even as they destroy their foes.`,
        image0: `/images/3-cards/STR/0.png`,
        image1: `/images/3-cards/HRT/1.png`,
        image2: `/images/3-cards/STR/2.png`,
        image3: `/images/3-cards/HRT/2.png`,
        heroImg: `/images/heroes/hero-giaco-04@6x.png`,
        prismImg: `/images/prisms/STH.png`
      },
      {
        title: `Agility + Wisdom`,
        arrayValue: [3, 1],
        subtitle: `Channel your inner calm and outer fury to become the flowing stream and the raging storm. Overtake and sweep away all that stand in your path to victory.`,
        image0: `/images/3-cards/AGL/0.png`,
        image1: `/images/3-cards/WIS/1.png`,
        image2: `/images/3-cards/AGL/2.png`,
        image3: `/images/3-cards/WIS/2.png`,
        heroImg: `/images/heroes/hero-giaco-11@6x.png`,
        prismImg: `/images/prisms/AGW.png`
      },
      {
        title: `Strength + Agility`,
        arrayValue: [3, 0],
        subtitle: `Strike with overwhelming might and speed in a one-two punch that will send opponents sprawling. Overrun and destroy all who would challenge you.`,
        image0: `/images/3-cards/STR/0.png`,
        image1: `/images/3-cards/AGL/1.png`,
        image2: `/images/3-cards/STR/2.png`,
        image3: `/images/3-cards/AGL/2.png`,
        heroImg: `/images/heroes/hero-giaco-03@6x.png`,
        prismImg: `/images/prisms/STA.png`
      },
      {
        title: `Heart + Agility`,
        arrayValue: [3, 2],
        subtitle: `Hit fast and hard with units that fight on beyond death, and spells that drain the life from your adversaries. Life is fleeting, that of your foes most of all. `,
        image0: `/images/3-cards/HRT/0.png`,
        image1: `/images/3-cards/AGL/1.png`,
        image2: `/images/3-cards/HRT/2.png`,
        image3: `/images/3-cards/AGL/2.png`,
        heroImg: `/images/heroes/hero-giaco-06@6x.png`,
        prismImg: `/images/prisms/HRA.png`
      },
      {
        title: `Heart + Wisdom`,
        arrayValue: [2, 1],
        subtitle: `Deep understanding and respect for the cycle of life grants you power few can imagine. Where others would fear death, you walk alongside it as an equal.`,
        image0: `/images/3-cards/HRT/0.png`,
        image1: `/images/3-cards/WIS/1.png`,
        image2: `/images/3-cards/HRT/2.png`,
        image3: `/images/3-cards/WIS/2.png`,
        heroImg: `/images/heroes/hero-giaco-07@6x.png`,
        prismImg: `/images/prisms/HRW.png`
      },
      {
        title: `Intellect + Strength`,
        arrayValue: [4, 0],
        subtitle: `Combine both physical might and mental fortitude to triumph over foes in both strength and strategy. Why settle for brain or brawn when you can wield both?`,
        image0: `/images/3-cards/INT/0.png`,
        image1: `/images/3-cards/STR/1.png`,
        image2: `/images/3-cards/INT/2.png`,
        image3: `/images/3-cards/STR/2.png`,
        heroImg: `/images/heroes/hero-giaco-08@6x.png`,
        prismImg: `/images/prisms/STI.png`
      },
      {
        title: `Agility + Intellect`,
        arrayValue: [4, 3],
        subtitle: `Outpace and outwit your foes at every turn with clever strategies and swift units and spells that will leave them spinning. Few can hope to match the speed of thought.`,
        image0: `/images/3-cards/AGL/0.png`,
        image1: `/images/3-cards/INT/1.png`,
        image2: `/images/3-cards/AGL/2.png`,
        image3: `/images/3-cards/INT/2.png`,
        heroImg: `/images/heroes/hero-giaco-10@6x.png`,
        prismImg: `/images/prisms/AGI.png`
      },
      {
        title: `Intellect + Wisdom`,
        arrayValue: [4, 1],
        subtitle: `Wield tactical prowess alongside mental and spiritual fortitude to outsmart and outlast your foes. True power lies not in simply knowing, but understanding.`,
        image0: `/images/3-cards/INT/0.png`,
        image1: `/images/3-cards/WIS/1.png`,
        image2: `/images/3-cards/INT/2.png`,
        image3: `/images/3-cards/WIS/2.png`,
        heroImg: `/images/heroes/hero-giaco-05@6x.png`,
        prismImg: `/images/prisms/INW.png`
      },
      {
        title: `Heart + Intellect`,
        arrayValue: [4, 2],
        subtitle: `Strategic prowess and the ability to manipulate life and death offers you endless tactical possibilities. Outsmart both your opponents, and death itself.`,
        image0: `/images/3-cards/HRT/0.png`,
        image1: `/images/3-cards/INT/1.png`,
        image2: `/images/3-cards/HRT/2.png`,
        image3: `/images/3-cards/INT/2.png`,
        heroImg: `/images/heroes/hero-giaco-09@6x.png`,
        prismImg: `/images/prisms/HRI.png`
      }
    ]
  },

  sectionEarn: {
    title: `COLLECT IN SKY. <br /> TRADE ANYWHERE.`,
    text: `Earn newly minted cards by winning battles and ascending the Skyweaver ranks. Play and trade.`
  },
  sectionEarlyAccess: {
    title: `Stay up to date`,
    subtitle: `Join us to stay up to date on all things Skyweaver! <br/>
    Learn about exclusives, in-game events, contests, community activities and so much more!
    `
  },
  sectionSpells: {
    spells: [
      {
        src: "/images/spells/1.png",
        title: "FREE TO PLAY"
      },
      {
        src: "/images/spells/2.png",
        title: "PLAY TO OWN"
      },
      {
        src: "/images/spells/3.png",
        title: "CASUAL OR COMPETITIVE"
      },
      {
        src: "/images/spells/4.png",
        title: "DUAL DECK COMBINATIONS"
      },
      {
        src: "/images/spells/5.png",
        title: "NON-ROTATING CARDS"
      },
      {
        src: "/images/spells/6.png",
        title: "INTUITIVE DECK BUILDING"
      },
      {
        src: "/images/spells/7.png",
        title: "TRUE OWNERSHIP"
      },
      {
        src: "/images/spells/8.png",
        title: "CROSS PLATFORM"
      }
    ]
  },
  sectionOwn: {
    img: `/images/features/trade-desktop.jpg`,
    mobileImg: `/images/features/trade-mobile.jpg`,
    title: `A TRADING CARD GAME WITH ACTUAL TRADING`,
    text: `Easily trade cards with other players in the Market. Silver and Gold cards you win or buy are your property, and you decide what to do with them. You can trade them, gift to a friend, or keep collecting and playing. The choice is yours.`,
  },
  sectionFTP: {
    title: `Free To Play`,
    text: `Unlock all 500+ Base cards for free as you level up to experience endless gameplay potential. Enter Discovery mode with a random deck or play Constructed once you've crafted your custom deck!`,
    img: `/images/features/bonfire-desktop.jpg`,
    mobileImg: `/images/features/bonfire-mobile.jpg`
  },
  sectionPTO: {
    title: `Play To Own: Rewarding Skill Like Never Before`,
    text: `Play competitively to climb the leaderboards and get rewarded for your mastery! Introducing an unprecedented weekly reward system where you can win tradable Silver NFT cards. Try your hand in Conquests to earn rare limited edition Gold NFT cards.`,
    img: `/images/features/leaderboard-desktop.jpg`,
    mobileImg: `/images/features/leaderboard-mobile.jpg`
  },
  sectionEternal: {
    title: `POWER TO THE PLAYER: ETERNAL NON-ROTATING CARDS`,
    text: `Skyweaver cards are eternal. Cards are never banned nor rotated out of play. This means the time you invest building up your skills, card collection and decks is never lost. All cards will always be relevant. 
    That’s a guarantee.`,
    img: `/images/features/ada-vs-horik-desktop.jpg`,
    mobileImg: `/images/features/ada-vs-horik-mobile.jpg`
  },
  sectionCraft: {
    title: `CRAFT THE MOST POWERFUL DECKS WITH EASE`,
    text: `Customize your deck according to your playstyle. The deck leaderboard lets you find out which decks are performing best, their stats, card makeup, and more! Use the deck string to build from it, customize it to your liking, and play right away.`,
    img: `/images/features/craft-desktop.jpg`,
    mobileImg: `/images/features/craft-mobile.jpg`
  },
  thankYouPage: {
    title: `THANK YOU FOR JOINING`,
    text: `
    By playing you’re joining the tens of thousands of players in the game right now! Be amongst the first to experience a true player-owned economy, compete and earn rare NFT cards, and trade your cards in the Market!
      <br /> <br/> 
      Join our community and chat with us directly on Discord at <a href="https://discord.gg/skyweaver" target="_blank" rel="noopener noreferrer">discord.gg/skyweaver</a> and follow us on our social channels using the links below to stay up to date on the latest about Skyweaver.
      <br/><br/>
      See you in Sky!
    `
  },
  thankYou2Page: {
    title: `THANK YOU FOR CONFIRMING!`,
    text: `
      Thanks for confirming that you're still interested in Skyweaver and want to stay on the Private Beta waitlist!
      <br /> <br/>
      We know some of you have been waiting a few months already, so we want to make sure that you're still interested in getting early access when we're ready to invite more people in the future. 
      <br /> <br/> 
      In the meantime, join our Discord at <a href="https://skyweaver.chat" target="_blank">skyweaver.chat</a>, make friends, and perhaps get a beta code faster 😉
      <br /><br />
      - The Horizon Games team
    `
  },
  accessThankYouPage: {
    title: `Welcome to Sky`,
    renderPlayButton: true,
  },
  creatorsThankYouPage: {
    title: `Welcome to Sky`,
    text: `
    Thank you for joining our Creators Program! Let's make cool stuff together 😊
    <br/><br/>
    In the meantime, come hang out and chat with us on Discord.
    <br/><br/>
    Love,
    `
  },
  redeemTempPage: {
    title: `Arriving Soon`,
    text: `
      Come back soon to redeem your code for Private Beta Access.
      <br /><br />
      Together, we'll pioneer the new digital dimension and weave new worlds!
      <br /><br />
      Love,
    `
  },
  FAQPage: {
    content: [
      {
        title: `Why is Skyweaver on the blockchain?`,
        content: `Skyweaver is currently available on PC, Mac, Linux, iOS and Android through the Arcadeum app. We are planning on having XBOX, Playstation and Nintendo Switch support in the upcoming year.`
      },
      {
        title: `Can I make real money just by playing Skyweaver matches?`,
        content: `Skyweaver is currently available on PC, Mac, Linux, iOS and Android through the Arcadeum app. We are planning on having XBOX, Playstation and Nintendo Switch support in the upcoming year.`
      },
      {
        title: `What platforms can I play Skyweaver on?`,
        content: `Skyweaver is currently available on PC, Mac, Linux, iOS and Android through the Arcadeum app. We are planning on having XBOX, Playstation and Nintendo Switch support in the upcoming year.`
      },
      {
        title: `What will Skyweaver be available to the general public`,
        content: `Skyweaver is currently available on PC, Mac, Linux, iOS and Android through the Arcadeum app. We are planning on having XBOX, Playstation and Nintendo Switch support in the upcoming year.`
      },
      {
        title: `How do I trade my Skyweaver cards?`,
        content: `Skyweaver is currently available on PC, Mac, Linux, iOS and Android through the Arcadeum app. We are planning on having XBOX, Playstation and Nintendo Switch support in the upcoming year.`
      },
      {
        title: `I found a bug.`,
        content: `Skyweaver is currently available on PC, Mac, Linux, iOS and Android through the Arcadeum app. We are planning on having XBOX, Playstation and Nintendo Switch support in the upcoming year.`
      }
    ]
  },
  mediaPage: {
    wallpapers: [
      { src: "wallpaper1.jpg", title: "Skyweaver 4k Wallpaper 1" },
      {
        src: "wallpaper2.jpg",
        title: "Skyweaver 4k Wallpaper 2"
      },
      {
        src: "wallpaper3.jpg",
        title: "Skyweaver 4k Wallpaper 3"
      },
      { src: "wallpaper5.jpg", title: "Skyweaver 4k Wallpaper 4" },
      { src: "wallpaper6.jpg", title: "Skyweaver 4k Wallpaper 5" },
      { src: "wallpaper7.jpg", title: "Skyweaver 4k Wallpaper 6" },
      { src: "wallpaper9.jpg", title: "Skyweaver 4k Wallpaper 7" },
      { src: "wallpaper8.jpg", title: "Skyweaver 4k Wallpaper 8" },
      { src: "wallpaper13.jpg", title: "Skyweaver 4k Wallpaper 9" },
      { src: "wallpaper14.jpg", title: "Skyweaver 4k Wallpaper 10" }
    ],
    screenshots: [
      {
        src: "ss1.jpg",
        title: "Skyweaver 4k Screenshot 1"
      },
      { src: "ss2.jpg", title: "Skyweaver 4k Screenshot 2" },
      { src: "ss3.jpg", title: "Skyweaver 4k Screenshot 3" },
      {
        src: "ss4.jpg",
        title: "Skyweaver 4k Screenshot 4"
      },
      { src: "ss5.jpg", title: "Skyweaver 4k Screenshot 5" },
      { src: "ss6.jpg", title: "Skyweaver 4k Screenshot 6" },
      { src: "ss7.jpg", title: "Skyweaver 4k Screenshot 7" },
      { src: "ss8.jpg", title: "Skyweaver 4k Screenshot 8" },
      { src: "ss9.jpg", title: "Skyweaver 4k Screenshot 9" },
      { src: "ss10.jpg", title: "Skyweaver 4k Screenshot 10" },
      { src: "ss11.jpg", title: "Skyweaver 4k Screenshot 11" },
      { src: "ss12.jpg", title: "Skyweaver 4k Screenshot 12" },
      { src: "ss13.jpg", title: "Skyweaver 4k Screenshot 13" },
      { src: "ss14.jpg", title: "Skyweaver 4k Screenshot 14" }
    ]
  }
};

export default data